<template>
    <div><input type="checkbox" :checked="value ? true : false" @click="onClick()" /></div>
</template>

<script>
import api from "./../../../../config/api";

export default {
  props: {
    value: [String, Number],
    values: Object,
    model: {
      type: Object,
      default: {},
    },
  },
  methods: {
      async onClick() {
          let v = this.value ? 0 : 1;
          await this.$axios.post(api.baseUrl + "caller/promo", {id: this.values.id, is_payed: v})
      }
  }
};
</script>