const modules = [
    {
        path: 'users',
        name: 'users-table',
        meta: { auth: 100 },
        componentIndex: () => {
            return import('@/views/users/Index.vue')
        },
        componentForm: () => {
            return import('@/views/users/From.vue')
        }
    }
];

const routes = [
    {
        path: '/index',
        name: 'index',
        meta: { auth: 10 },
        component: () => {
            return import('@/views/index/index.vue')
        }
    },
    {
        path: '/config',
        name: 'config',
        meta: { auth: 10 },
        component: () => {
            return import('@/views/config.vue')
        }
    },
    {
        path: 'promo',
        name: 'promo',
        meta: { auth: 100 },
        component: () => {
            return import('@/views/promoIndex.vue')
        },
    },
    {
        path: '/table/:api/:view',
        name: 'index-table',
        meta: { auth: 10 },
        component: () => {
            return import('@/views/caller/table/index.vue')
        }
    },
    {
        path: '/event-table/:api/:view',
        name: 'event-table',
        meta: { auth: 10 },
        component: () => {
            return import('@/views/caller/table/event.vue')
        }
    },
    {
        path: '/call-table/:api/:view',
        name: 'call-table',
        meta: { auth: 10 },
        component: () => {
            return import('@/views/caller/table/call.vue')
        }
    },
    {
        path: 'card-items/:api/:view',
        name: 'card-items',
        meta: { auth: 10 },
        component: () => {
            return import('@/views/caller/table/order.vue')
        }
    },
    {
        path: 'get-card-item',
        name: 'get-card-item',
        meta: { auth: 10 },
        component: () => {
            return import('@/views/caller/card/getNew.vue')
        }
    },
    {
        path: 'get-card-id/:id',
        name: 'get-card-id',
        meta: { auth: 10 },
        component: () => {
            return import('@/views/caller/card/getId.vue')
        }
    },
    {
        path: 'get-card-phone/:phone',
        name: 'get-card-phone',
        meta: { auth: 10 },
        component: () => {
            return import('@/views/caller/card/getPhone.vue')
        }
    },
    {
        path: 'statistic',
        name: 'statistic',
        meta: { auth: 10 },
        component: () => {
            return import('@/views/statistic/index.vue')
        }
    }
];

for (let i = 0; i < modules.length; i++) {
    const module = modules[i];
    routes.push(
        {
            path: "/" + module.path + "/:api/:view",
            name: module.name,
            meta: module.meta,
            component: module.componentIndex
        }
    );
    routes.push(
        {
            path: "/" + module.path + "/:api/:view/create",
            name: module.name + "-create",
            meta: module.meta,
            component: module.componentForm
        }
    );
    routes.push(
        {
            path: "/" + module.path + "/:api/:view/update/:id",
            name: module.name + "-update",
            meta: module.meta,
            component: module.componentForm
        }
    );
}

export default {
    routes: routes
}