import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=a81aeed6&"
import script from "./Card.vue?vue&type=script&lang=js&"
export * from "./Card.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../www/av100caller/av100caller_lk/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../www/av100caller/av100caller_lk/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardActions,VCardText,VContainer,VIcon,VSpacer,VToolbar,VToolbarTitle})
