var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"my-2"},[_c('v-btn',{on:{"click":function($event){return _vm.save()}}},[_vm._v("Записать")]),_c('v-btn',{on:{"click":function($event){return _vm.load()}}},[_vm._v("Загрузить")])],1),_c('div',[_c('editor',{attrs:{"api-key":"no-api-key","init":{
        height: 500,
        menubar: false,
        plugins: [
          'advlist autolink lists link image charmap print preview anchor',
          'searchreplace visualblocks code fullscreen',
          'insertdatetime media table paste code help wordcount' ],
        toolbar:
          'undo redo | formatselect | bold italic backcolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | removeformat | help',
      }},model:{value:(_vm.data),callback:function ($$v) {_vm.data=$$v},expression:"data"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }