import { render, staticRenderFns } from "./phones.vue?vue&type=template&id=81cc537a&"
import script from "./phones.vue?vue&type=script&lang=js&"
export * from "./phones.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../www/av100caller/av100caller_lk/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports