<template>
  <a-dialog-form :value="value" @input="$emit('input')">
    <template v-slot>
      <v-card>
        <v-card-title class="headline"
          >Входящий звонок<v-spacer></v-spacer
          ><v-btn
            @click="
              $emit('input');
              $emit('answer');
            "
            color="primary"
            class="ma-1"
            >Ответить</v-btn
          ><v-btn @click="closeWindow()" color="error" class="ma-1"
            >Отклонить</v-btn
          ></v-card-title
        >
        <v-card-text>
          <a-loader v-if="loading" />
          <h1>{{ phone }}</h1>
        </v-card-text>
      </v-card>
    </template>
  </a-dialog-form>
</template>

<script>
import genModelBase from "@/share/components/libs/genModelBase";

export default {
  mixins: [genModelBase],
  props: {
    value: Boolean,
    phone: String,
  },
  data() {
    return {
      loading: false,
      data: {},
    };
  },
  created() {
    this.fetchData();
  },
  watch: {
    phone() {
      this.fetchData();
    },
  },
  computed: {
    model() {
      // return this.generateModel("index", "auto_offers");
    },
  },
  methods: {
    closeWindow() {
      this.$emit("input");
      this.$emit("end-call");
    },
    async fetchData() {
      // this.data = {};
      // this.loading = true;
      // let response = await this.$axios.g(
      //   this.$root.api["basic"] + "/auto_offers",
      //   {
      //     params: {
      //       sort: {
      //         key: "credate",
      //         order: "DESC",
      //       },
      //       filters: { phone: this.phone },// credate: (new Date()).addDays(-90).date + " - " + (new Date()).date },
      //       pager: { page: 0, count: 0, limit: 1 },
      //     },
      //   }
      // );
      // this.loading = false;
      // if (response.data.data[0]) this.data = response.data.data[0];
    },
  },
};
</script>