<template>
  <a-dialog-form :value="value" @input="closeWindow()" :maxWidth="1200">
    <template v-slot>
      <v-card>
        <v-card-title class="headline"
          >Карточка {{ phone }}
          <v-btn @click="$root.$emit('global-end-call')" color="error" class="ma-1"
            >Положить трубку</v-btn
          ><v-spacer></v-spacer
          ><v-btn @click="closeWindow()" color="error" class="ma-1"
            >Закрыть</v-btn
          ></v-card-title
        >
        <v-card-text>
          <get-card-phone v-if="phone" :phone="phone" />
          <get-card v-else />
        </v-card-text>
      </v-card>
    </template>
  </a-dialog-form>
</template>

<script>
export default {
  components: {
    GetCard: () => import("@/views/caller/card/card"),
    GetCardPhone: () => import("@/views/caller/card/getPhone"),
  },
  props: {
    value: Boolean,
    phone: [String, Number],
  },
  methods: {
    closeWindow() {
      this.$emit("input");
    },
  },
};
</script>