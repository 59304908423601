<template>
  <div>
    <div class="my-2">
      <v-btn @click="save()">Записать</v-btn>
      <v-btn @click="load()">Загрузить</v-btn>
    </div>
    <div>
      <!-- <quill-editor v-model="data" :options="editorOption" /> -->
      <editor
        v-model="data"
        api-key="no-api-key"
        :init="{
          height: 500,
          menubar: false,
          plugins: [
            'advlist autolink lists link image charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount',
          ],
          toolbar:
            'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat | help',
        }"
      />
    </div>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue";
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
// import QuillBetterTable from 'quill-better-table'
// import { quillEditor } from "vue-quill-editor";
// // quillEditor.register({
// //   'modules/better-table': QuillBetterTable
// // }, true)

export default {
  components: {
    editor: Editor,
    // quillEditor,
  },
  data() {
    return {
      editorOption: {
        modules: {
          table: true,
        },
      },
      data: "",
    };
  },
  created() {
    this.loads();
  },
  methods: {
    loads() {
      this.data = this.$root.config.meta.const.scriptText;
    },
    async save() {
      await this.$axios.post(this.$root.api.baseUrl + "config", {
        name: "scriptText.json",
        data: {
          text: this.data,
        },
      });
    },
  },
};
</script>