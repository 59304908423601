<template>
  <span>{{ v }}</span>
</template>

<script>
export default {
  props: {
    value: String,
  },
  computed: {
    v() {
      if (!this.value) return "";
      const d = new Date(this.value);
      if (d)
        return (
          d.toLocaleDateString("ru-RU") + " " + d.toLocaleTimeString("ru-RU")
        );
      return "";
    },
  },
};
</script>