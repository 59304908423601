<template>
  <div>
    <a :href="getURL()" target="_blank">
      <img
        :src="getURL() + '?width=' + height + '&height=' + height"
        :style="{ height: height + 'px' }"
      />
    </a>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: {
      type: Object,
      default: {},
    },
    height: {
      type: Number,
      default: 50,
    },
  },
  methods: {
    getURL() {
      let val;
      try {
        val = JSON.parse(this.value)[0];
      } catch (error) {}
      console.log(val);
      if (typeof val === "object") {
        if (val.href) {
          return val.href;
        } else if (val.file) {
          return this.$root.api.image_download + "/" + val.file;
        }
      } else {
        return this.$root.api.image_download + "/" + val;
      }
    },
  },
};
</script>