// import { onBackgroundMessage } from "firebase/messaging/sw";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCxf2xw5NztOBpAWNZ9zJJS9DGB9gZchJ0",
    authDomain: "lk-av100-callbaza-ru.firebaseapp.com",
    projectId: "lk-av100-callbaza-ru",
    storageBucket: "lk-av100-callbaza-ru.appspot.com",
    messagingSenderId: "950417612068",
    appId: "1:950417612068:web:dfdfb1fc708260670c27b1",
    measurementId: "G-3Z8K900SPK"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const messaging = getMessaging();

onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);
    // ...
});
getToken(messaging, { vapidKey: 'BH7-R31Pjbv0iuN9N5g7w1zUhtB94z1x0tUauqAppU4HPbO1u4HNWpRBG8xuVeRC3REAeqB5B_3KkhBrNr43yV4' }).then((currentToken) => {
    if (currentToken) {
        console.log('currentToken ', currentToken);
        // Send the token to your server and update the UI if necessary
        // ...
    } else {
        // Show permission request UI
        console.log('No registration token available. Request permission to generate one.');
        // ...
    }
}).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // ...
});

// onBackgroundMessage(messaging, (payload) => {
//   console.log('[firebase-messaging-sw.js] Received background message ', payload);
//   // Customize notification here
//   const notificationTitle = 'Background Message Title';
//   const notificationOptions = {
//     body: 'Background Message body.',
//     icon: '/firebase-logo.png'
//   };

//   self.registration.showNotification(notificationTitle,
//     notificationOptions);
// });