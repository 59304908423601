import Vue from 'vue'
import axios from "axios"

const token = localStorage.getItem('user-token');

if (token) {
    axios.defaults.headers.common['Authorization'] = token;
}

axios.interceptors.response.use(undefined, function (err) {
    return new Promise(function (resolve, reject) {
        if (err.response.status === 400) {
            Vue.$emit('show-info', {text: err.response.response.message})
        } else if (err.response.status === 403) {
            localStorage.removeItem('user-token')
            localStorage.removeItem('user-profile')
            window.location.href = '/';
        }
        throw err;
    });
});

axios.g = async function(...args) {
    return await axios.get(...args);
}
axios.p = async function(...args) {
    return await axios.post(...args);
}

Vue.prototype.$axios = axios

export default axios