<template>
  <div>{{ v[0][model.field] }}</div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: {
      type: Object,
      default: {},
    },
  },
  computed: {
    v() {
      let d = [];
      try {
        d = JSON.parse(this.value);
      } catch {}
      return d;
    },
  },
};
</script>