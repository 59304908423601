import { render, staticRenderFns } from "./Input.vue?vue&type=template&id=e0880f2c&"
import script from "./Input.vue?vue&type=script&lang=js&"
export * from "./Input.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../www/av100caller/av100caller_lk/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../www/av100caller/av100caller_lk/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VInput } from 'vuetify/lib/components/VInput';
import { VLabel } from 'vuetify/lib/components/VLabel';
installComponents(component, {VInput,VLabel})
