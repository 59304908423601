const BASE_URL = 'https://api.av100.callbaza.ru/api/';


export default {
    baseUrl: BASE_URL,
    config: BASE_URL + 'config',
    token_login: BASE_URL + 'signup/get_token',
    set_profile: BASE_URL + 'signup/set_profile',
    get_profile: BASE_URL + 'signup/get_profile',
    login_exists: BASE_URL + 'signup/login_exists',
    activation: BASE_URL + 'signup/activation',
    file_upload: BASE_URL + 'file/upload',
    file_download: BASE_URL + 'file/download',
    image_upload: BASE_URL + 'image/upload',
    image_download: BASE_URL + 'image/download',
    basic: BASE_URL + 'basic',

    users: BASE_URL + 'users/users',
    
    cardGetNew: BASE_URL + 'caller/item-get-new',
    cardGetByPhone: BASE_URL + 'caller/item-get-by-phone',
    cardItemImport: BASE_URL + 'caller/item-import',
    cardItemPromoImport: BASE_URL + 'caller/item-promo-import',
    cardItemPromoImportAV100: BASE_URL + 'caller/item-import-av100',
    cardItem: BASE_URL + 'caller/item',
    cardEvent: BASE_URL + 'caller/event/caller_events',
    cardManagerEvent: BASE_URL + 'caller/manager_event/caller_events',
    cardRequest: BASE_URL + 'caller/request/caller_request',
    cardActiveManagerRequest: BASE_URL + 'caller/active_manager_request/caller_request',
    cardActiveRequest: BASE_URL + 'caller/active_request/caller_request',
    cardManagerRequest: BASE_URL + 'caller/manager_request/caller_request',
    cardCalls: BASE_URL + 'caller/calls/call_log',
    cardManagerCalls: BASE_URL + 'caller/calls-manager/call_log',
    sendsms: BASE_URL + 'caller/sendsms',
    sendtele: BASE_URL + 'caller/sendtele',
    sendbot: BASE_URL + 'caller/sendsms/bot',
    sendWhatsappbot: BASE_URL + 'caller/whatsappbot/send',
}